import React from "react";
import { Line, Bar, Doughnut } from "react-chartjs-2";
import fpPiggyIcon from "../../../img/overdueIcon.png";
const AnalyticsHome = () => {
  const labels = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const generateRandomData = (min, max, count) => {
    return Array.from(
      { length: count },
      () => Math.floor(Math.random() * (max - min + 1)) + min
    );
  };
  const firstDataset = generateRandomData(8000, 12000, labels.length);
  const secondDataset = firstDataset.map((value) => {
    const reductionPercentage = Math.random() * (0.5 - 0.3) + 0.3; // Random reduction between 30% and 50%
    return Math.floor(value * (1 - reductionPercentage));
  });
  const dataLine = {
    labels: ["Jan", "Feb", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "Funded",
        data: [10, 9, 9, 10, 8, 8, 10],
        borderColor: "rgba(153, 102, 255, 0.8)",
        backgroundColor: "rgba(153, 102, 255, 0.8)",
        fill: false, // Don't fill the area under the line
        tension: 0.1, // Smooth the line
        barThickness: 15,
      },
      {
        label: "Received",
        data: [9, 8.5, 7.5, 8, 8, 6, 9.5],
        borderColor: "rgba(153, 102, 255, 0.4)",
        backgroundColor: "rgba(153, 102, 255, 0.4)",
        fill: false, // Don't fill the area under the line
        tension: 0.1, // Smooth the line
        barThickness: 15,
      },
    ],
  };

  const optionsLine = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.dataset.label}: ${context.raw}`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Month",
        },
        // To simulate a range, you may need to manage visible labels and data
        // If you need numerical ranges, consider switching to a numerical scale
      },
      y: {
        title: {
          display: true,
          text: "Value",
        },
        beginAtZero: true,
        // Define the range for the y-axis here
        min: 0,
        max: 12, // Adjust based on the range of your data
      },
    },
  };

  /* const data = {
    labels,
    datasets: [
      {
        type: "line",
        label: "Receivables",
        data: [5000, 4000, 4500, 3500, 4500, 5000, 4000],
        backgroundColor: "rgba(0, 206, 182, 0.46)",
        borderColor: "rgba(0, 88, 122, 0.6)",
        barThickness: 25,
        tension: 0.4,
      },
    ],
  };*/
  const data = {
    labels: ["Jan", "Feb", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "Loan Book",
        data: [10000, 9000, 10000, 10000, 9000, 9000, 9500],
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        fill: true,
      },
      {
        label: "Utilisation",
        data: [5000, 4800, 4000, 5500, 4500, 6000, 4000],
        borderColor: "rgba(25, 69, 107, 0.6)",
        backgroundColor: "rgba(25, 69, 107, 0.2)",
        fill: true,
      },
    ],
  };

  const graph = {
    labels: [],
    datasets: [
      {
        label: "Amount",
        data: [],
        backgroundColor: ["#58B07E"],

        borderWidth: 1,
        fill: false,
        backgroundColor: "rgba(0, 206, 182, 0.15) ",
        borderColor: "rgba(0, 88, 122, 0.6)",
        pointBackgroundColor: "#19456b",
      },
    ],
  };
  {
    /*--------------------------------------------------------------*/
  }
  const dataD = {
    labels: ["Funded", "Overdue", "Availability"],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 8],
        backgroundColor: [
          "rgba(137, 101, 229, 0.13)",
          "rgba(255, 102, 146, 0.16)",
          "rgba(0, 206, 182, 0.05)",
        ],
        borderColor: [
          "rgba(137, 101, 229, 1)",
          "rgba(255, 102, 146, 1)",

          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    responsive: true,
    cutoutPercentage: 75,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.raw !== null) {
              label += `${context.parsed.raw}`;
            }
            return label;
          },
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: "Month",
          },
          min: 0,
          max: 12000, // Adjust based on the range of your data
          // To simulate a range, you may need to manage visible labels and data
          // If you need numerical ranges, consider switching to a numerical scale
        },
        y: {
          title: {
            display: true,
            text: "Value",
          },
          beginAtZero: true,
          // Define the range for the y-axis here
          min: 0,
          max: 12000, // Adjust based on the range of your data
        },
      },
    },
  };
  return (
    <>
      <h6>Analytics Home</h6>
      <hr />
      <div className="row">
        <div className="col-sm-4">
          <div className="card ">
            <div className="card-body an-card-purple  ">
              <label className="statistics-title">
                Total Funded vs Received
              </label>
              <h5 className="rate-percentage">$8,500.00 - $6,000</h5>
              <Bar data={dataLine} options={optionsLine} />
              <span className="w-14 h-10 rounded-full flex items-center justify-center text-white  mt-5  an-bg-purple pull-right">
                4% <i className="fa fa-level-down " />
              </span>
            </div>
          </div>
        </div>
        {/*    <div className="col-sm-2">
          <div className="card quarter-circle">
            <div className="card-body   an-card-primary   ">
              <label className="statistics-title">Payment Received</label>
              <h5 className="rate-percentage">$5,500.00</h5>
              <span className="w-14 h-10 rounded-full flex items-center justify-center text-white  mt-5 bg-primary pull-right">
                5% <i className="fa fa-level-up " />
              </span>
            </div>
          </div> 
  </div> */}
        <div className="col-sm-2">
          <div className="card">
            <div className="card-body   an-card-secondary  ">
              <label className="statistics-title">Profit Received</label>
              <h5 className="rate-percentage">$5,500.00</h5>
              <span className="w-14 h-10 rounded-full flex items-center justify-center text-white  mt-5 bg-secondary pull-right">
                5% <i className="fa fa-level-up " />
              </span>
            </div>
          </div>
        </div>
        <div className="col-sm-2">
          <div className="card ">
            <div className="card-body an-card-primary   ">
              <label className="statistics-title">New Clients</label>
              <h5 className="rate-percentage">5</h5>
              <div className="stat-percent text-primary">
                <span className="badge bg-primary-transparent p-1 mt-5">
                  This Month
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="card ">
            <div className="card-body  bg-danger text-white">
              <label className="statistics-title">Overdue</label>
              <h3 className="rate-percentage">
                $18,500.00
                <img className="pull-right" src={fpPiggyIcon}></img>
                {/* <i className="fa fa-calendar fa-4x text-white pull-right"></i>*/}
              </h3>

              <div className="flex align-center rounded-full justify-between bg-gray-800-10 w-fit mt-4">
                <div className="py-3 px-6 text-center">
                  <h5 className="text-white text-lg leading-[normal]">15</h5>
                  <small className="text-white text-xs font-medium  block ">
                    Clients
                  </small>
                </div>
                <div className="py-3 px-6 text-center border-s border-white-20">
                  <h5 className="text-white text-lg leading-[normal]">87</h5>
                  <small className="text-white text-xs font-medium  block ">
                    Invoices
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-8">
          <div className="card">
            <div className="card-body ">
              <div className="row">
                <div className="col-sm-8 p-4">
                  <h6>Last 6 months</h6>
                  <Line data={data} options={options} />
                </div>
                <div className="col-sm-4 p-4">
                  <ul className="stat-list">
                    <li>
                      <h6 className="no-margins">$2,346.00</h6>
                      <small className="text-muted">Supply Chain</small>
                      <div className="stat-percent">48%</div>
                      <div className="an-progress progress-mini">
                        <div
                          style={{ width: "48%" }}
                          className="progress-bar"
                        />
                      </div>
                    </li>
                    <li>
                      <h6 className="no-margins ">$4,422.00</h6>
                      <small className="text-muted">Trade Finance</small>
                      <div className="stat-percent">60%</div>
                      <div className="an-progress progress-mini">
                        <div
                          style={{ width: "60%" }}
                          className="progress-bar"
                        />
                      </div>
                    </li>
                    <li>
                      <h6 className="no-margins ">$9,180.00</h6>
                      <small className="text-muted">Invoice Finance</small>
                      <div className="stat-percent">22%</div>
                      <div className="an-progress progress-mini">
                        <div
                          style={{ width: "22%" }}
                          className="progress-bar"
                        />
                      </div>
                    </li>
                    <li>
                      <h6 className="no-margins ">$9,180.00</h6>
                      <small className="text-muted">Business Loans</small>
                      <div className="stat-percent">22%</div>
                      <div className="an-progress progress-mini">
                        <div
                          style={{ width: "22%" }}
                          className="progress-bar"
                        />
                      </div>
                    </li>
                    <li>
                      <h6 className="no-margins ">$9,180.00</h6>
                      <small className="text-muted">Whole of book</small>
                      <div className="stat-percent">22%</div>
                      <div className="an-progress progress-mini">
                        <div
                          style={{ width: "22%" }}
                          className="progress-bar"
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="progress progress-striped active mt-4">
                <div
                  style={{ width: "30%" }}
                  className="progress-bar bg-primary"
                >
                  <span className="sr-only">30% Complete (success)</span>
                </div>

                <div
                  style={{ width: "40%" }}
                  className="progress-bar bg-danger"
                >
                  <span className="sr-only">40% Complete (danger)</span>
                </div>
              </div>
              <div className="row mb-2 mt-2">
                <div className="col-4 text-center">
                  <h6 className="   mb-1 ">
                    <span>$25,500,00.00</span>
                  </h6>
                  <span>
                    <span className="text-muted   ml-1">Funded</span>
                  </span>
                </div>
                <div className="col-4 text-center">
                  <h6 className="   mb-1 ">$12,025,20.00 </h6>
                  <span>
                    <span className="text-muted   ml-1">In Arrears</span>
                  </span>
                </div>
                <div className="col-4 text-center">
                  <h6 className="   mb-1">
                    <span>$40 M</span>
                  </h6>
                  <span className="text-xs">Total Book</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="card">
            <div className="card-body ">
              <ul class="nav nav-tabs">
                <li class="active">
                  <a href="#">Top Performing Clients</a>
                </li>
                <li>
                  <a href="#">Worst Performing Clients</a>
                </li>
              </ul>
              {/* <Doughnut data={dataD} options={options} /> */}

              <div className="list-group list-group-flush rounded-xl">
                <div>
                  <div className="list-group-item list-group-item-action flex-column align-items-start rounded-xl border-0 mt-2 mb-2 position-relative">
                    <div className="d-sm-flex align-items-center">
                      <div className="d-flex card-mobile-view justify-content-center">
                        <div className="avatar d-none d-sm-inline-flex avatar-md text-secondary bg-primary mr-2 font-weight-bold rounded-full mob-avatar">
                          <span className="text-white">85</span>
                        </div>
                        <div className="mb-3 mb-sm-0 mob-card-text text-center text-sm-left">
                          <strong>Tismor Health</strong>
                          <br />
                          <small className="badge badge-primary-transparent bg-primary-transparent mr-2 text-bold p-2">
                            2M Limit & 95% Utilization
                          </small>
                          <span className="text-danger"> </span>
                        </div>
                      </div>
                      <div className="ml-auto text-sm-right text-center mg-t-5 mob-card-right">
                        <h6 className="mb-0 text-secondary font-weight-bold">
                          <span>$1,680,017.45</span>
                        </h6>
                        <span className="badge badge-primary-transparent bg-danger-transparent p-1">
                          <small className="font-weight-bold text-danger">
                            No Arrears<span></span>
                          </small>
                        </span>
                      </div>
                      <div
                        className="dropdown position-absolute"
                        style={{ right: "-20px", top: 10 }}
                      >
                        <button
                          className="btn btn-transparent"
                          type="button"
                          id="dropdownMenuButton0"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton0"
                        >
                          <a className="dropdown-item">View Notes</a>
                          <a className="dropdown-item">View All Transactions</a>
                          <a className="dropdown-item">
                            View Historic Repayment{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="list-group-item list-group-item-action flex-column align-items-start rounded-xl border-0 mt-2 mb-2 position-relative">
                    <div className="d-sm-flex align-items-center">
                      <div className="d-flex card-mobile-view justify-content-center">
                        <div className="avatar d-none d-sm-inline-flex avatar-md text-secondary bg-primary mr-2 font-weight-bold rounded-full mob-avatar">
                          <span className="text-white">78</span>
                        </div>
                        <div className="mb-3 mb-sm-0 mob-card-text text-center text-sm-left">
                          <strong>Msquare Energy Pty Ltd </strong>
                          <br />
                          <small className="badge badge-primary-transparent bg-primary-transparent mr-2 text-bold p-2">
                            1.5M Limit & 90% Utilization
                          </small>
                          <span className="text-danger"> </span>
                        </div>
                      </div>
                      <div className="ml-auto text-sm-right text-center mg-t-5 mob-card-right">
                        <h6 className="mb-0 text-secondary font-weight-bold">
                          <span>$1,252,233.47</span>
                        </h6>
                        <span className="badge badge-danger-transparent bg-danger-transparent">
                          <small className="  text-danger font-weight-bold">
                            <span>No Arrears</span>
                          </small>
                        </span>
                      </div>
                      <div
                        className="dropdown position-absolute"
                        style={{ right: "-20px", top: 10 }}
                      >
                        <button
                          className="btn btn-transparent"
                          type="button"
                          id="dropdownMenuButton1"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <a className="dropdown-item">View Notes</a>
                          <a className="dropdown-item">View All Transactions</a>
                          <a className="dropdown-item">
                            View Historic Repayment{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="list-group-item list-group-item-action flex-column align-items-start rounded-xl border-0 mt-2 mb-2 position-relative">
                    <div className="d-sm-flex align-items-center">
                      <div className="d-flex card-mobile-view justify-content-center">
                        <div className="avatar d-none d-sm-inline-flex avatar-md text-secondary bg-secondary  mr-2 font-weight-bold rounded-full mob-avatar">
                          <span className="text-white">64</span>
                        </div>
                        <div className="mb-3 mb-sm-0 mob-card-text text-center text-sm-left">
                          <strong>The Product Group Pty Ltd</strong>
                          <br />
                          <small className="badge badge-warning-transparent bg-warning-transparent mr-2  p-2">
                            800 Limit 55 % Utilization
                          </small>
                          <span className="text-danger"> </span>
                        </div>
                      </div>
                      <div className="ml-auto text-sm-right text-center mg-t-5 mob-card-right">
                        <h6 className="mb-0 text-secondary font-weight-bold">
                          <span>$371,481.92</span>
                        </h6>
                        <span className="badge badge-danger-transparent bg-danger-transparent font-weight-bold">
                          <small className=" text-danger  font-weight-bold">
                            Arrears: <span>$50,000.00</span>
                          </small>
                        </span>
                      </div>
                      <div
                        className="dropdown position-absolute"
                        style={{ right: "-20px", top: 10 }}
                      >
                        <button
                          className="btn btn-transparent"
                          type="button"
                          id="dropdownMenuButton2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton2"
                        >
                          <a className="dropdown-item">View Notes</a>
                          <a className="dropdown-item">View All Transactions</a>
                          <a className="dropdown-item">
                            View Historic Repayment{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="list-group-item list-group-item-action flex-column align-items-start rounded-xl border-0 mt-2 mb-2 position-relative">
                    <div className="d-sm-flex align-items-center">
                      <div className="d-flex card-mobile-view justify-content-center">
                        <div className="avatar d-none d-sm-inline-flex avatar-md text-secondary bg-indigo mr-2 font-weight-bold rounded-full mob-avatar">
                          <span className="text-white">58</span>
                        </div>
                        <div className="mb-3 mb-sm-0 mob-card-text text-center text-sm-left">
                          <strong>I.A. Boarding Pty Ltd</strong>
                          <br />
                          <small className="badge badge-secondary-transparent bg-secondary-transparent mr-2 text-muted">
                            5 Invoices
                          </small>
                          <span className="text-danger"> </span>
                        </div>
                      </div>
                      <div className="ml-auto text-sm-right text-center mg-t-5 mob-card-right">
                        <h6 className="mb-0 text-secondary font-weight-bold">
                          <span>$217,856.42</span>
                        </h6>
                        <span className="badge badge-danger-transparent bg-danger-transparent">
                          <small className="text-muted text-danger">
                            Fees: <span>$5,546.19</span>
                          </small>
                        </span>
                      </div>
                      <div
                        className="dropdown position-absolute"
                        style={{ right: "-20px", top: 10 }}
                      >
                        <button
                          className="btn btn-transparent"
                          type="button"
                          id="dropdownMenuButton3"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton3"
                        >
                          <a className="dropdown-item">View Notes</a>
                          <a className="dropdown-item">View All Transactions</a>
                          <a className="dropdown-item">
                            View Historic Repayment{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="list-group-item list-group-item-action flex-column align-items-start rounded-xl border-0 mt-2 mb-2 position-relative">
                    <div className="d-sm-flex align-items-center">
                      <div className="d-flex card-mobile-view justify-content-center">
                        <div className="avatar d-none d-sm-inline-flex avatar-md text-white bg-danger  mr-2 font-weight-bold rounded-full mob-avatar">
                          <span className="text-white">27</span>
                        </div>
                        <div className="mb-3 mb-sm-0 mob-card-text text-center text-sm-left">
                          <strong>
                            Smart Business Investment Group Pty Ltd
                          </strong>
                          <br />
                          <small className="badge badge-secondary-transparent bg-secondary-transparent mr-2 text-muted">
                            1 Invoices
                          </small>
                          <span className="text-danger"> </span>
                        </div>
                      </div>

                      <div className="ml-auto text-sm-right text-center mg-t-5 mob-card-right">
                        <h6 className="mb-0 text-secondary font-weight-bold">
                          <span>$212,719.07</span>
                        </h6>
                        <span className="badge badge-danger-transparent bg-danger-transparent">
                          <small className="text-muted text-danger">
                            Fees: <span>$12,791.07</span>
                          </small>
                        </span>
                      </div>
                      <div
                        className="dropdown position-absolute"
                        style={{ right: "-20px", top: 10 }}
                      >
                        <button
                          className="btn btn-transparent"
                          type="button"
                          id="dropdownMenuButton4"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="fa fa-ellipsis-v" />
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton4"
                        >
                          <a className="dropdown-item">View Notes</a>
                          <a className="dropdown-item">View All Transactions</a>
                          <a className="dropdown-item">
                            View Historic Repayment{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AnalyticsHome;
