import React from "react";
import { Link } from "react-router";
import './analytics.css'; 

import fpClientIcon from "../../../img/fp-icon.svg";
const AnalyticsLayout = ({children}) =>
{
    
    return(
        <>
      <header>
                <nav className="navbar navbar-expand-sm bg-secondary navbar-dark">
                <Link className="navbar-brand d-sm-none mr-auto" to="/analytics/home"> <img src={fpClientIcon} className="d-inline-block align-top" /></Link >
                    <ul className="navbar-nav ml-4">
                        <li className="nav-item active">
                            <a className="nav-link" href="#">
                                Home
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">
                                clients
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">
                                Credits
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">
                                Payments
                            </a>
                        </li>
                    </ul>
                   
                    <div className="d-flex align-items-center ml-auto">
                        <input
                            type="text"
                            className="form-control form-control-sm mr-2"
                            value="27/08/2024"
                        />
                        <i className="fa fa-home text-white"></i>
                    </div>
                </nav>
            </header>
            <div className="analytics-container">
                {children}
            </div>
        </>
    );

}
 export default AnalyticsLayout;