import React, { useContext, useEffect, useRef, useState } from "react";
import Joi from "joi-browser";
import { FormGroup } from "../../common/containers/formContainer";
import Modal from "react-bootstrap/Modal";
import {
  TextInput,
  GeneralButton,
  CurrencyText,
  CurrencyLabel,
  DateTimePicker,
  Picker,
} from "../../common/inputs";

import * as moment from "moment";
import { toast } from "react-toastify";
import _ from "lodash";

import EntityContext from "../../../context/entityContext";
import TransactionContext from "../../../context/invTransactionContext";

import useForm from "../../../hooks/useForm";
import useApi from "../../../hooks/useApi";

import authService from "../../../services/authService";
import coreService from "../../../services/coreService";
import transactionService from "../../../services/invTransactions";
import LoadingSpinner from "../../common/loaders/loadingSpinner";
import CommonHeader from "../invoice/commonHeader";
import ParticipantSummary from "../invoice/participantSummary";

export default function AddParticipant({
  onClose,
  totalInvAmt,
  totalAgreementAmt,
  totalAdvanceAmt,
  annualRate,
  transId = null,
  context = true,
  invStatus = null,
  showSummary = false,
  handleReload,
  selectedRow = null,
}) {
  //------------Api Services--------------------------------------------
  const transactionApi = useApi(transactionService.createPaFunding);
  const transactionParticipantApi = useApi(
    transactionService.getParticipantDetails
  );
  // -------------------------------------------------------------------

  const entityContext = useContext(EntityContext);
  const { country_id } = authService.getCurrentUser();

  const [investors, setInvestors] = useState([]);
  const [transData, setTransData] = useState([]);
  const [openParticiapnt, setParticipant] = useState(true);
  const [totalAmtInvested, setTotalAmtInvested] = useState(0);
  const [isUpdated, setisupdated] = useState(false);

  //Creating references for Input fields
  const participantRef = useRef();
  const participationAmtRef = useRef();
  const AnnualRateRef = useRef();
  const PaidDateRef = useRef();
  const EndDateRef = useRef();
  

  const [selectedParticipant, setselectedParticipant] = useState({
    label: "Select Participant",
    value: null,
  });
  const [availableAmt, setAvailableAmt] = useState(0);
  const [fee, setFee] = useState(0);

  const [participants, setParticipants] = useState([]);
  let addParticipant;

  let contextObj = useContext(TransactionContext);

  if (context === true) {
    participants = contextObj.participants;
    addParticipant = contextObj.addParticipant;
  }

  //------------------Validation Schema-----------------------------
  const schema = Joi.object() // Form validation schema object
    .keys({
      participation_amt: Joi.number()
        .min(1)
        .required()
        .error(() => {
          participationAmtRef.current.focus();
          return { message: "Please enter a valid Participation Amt"};
        }),

      participation_amt_annual_rate: Joi.number()
        .required()
        .error(() => {
          AnnualRateRef.current.focus();
          return { message: "Annual Value / Fixed Fee Charged cannot be blank"};
        }),

      particiation_amt_paid_date: Joi.date()
        .required()
        .error(() => {
          document.getElementById('particiation_amt_paid_date').focus();
          return {
            message: "Start date cannot be blank",
          };
        }),

      // particiation_amt_end_date: Joi.date()
      //   .required()
      //   .error(() => {
      //     document.getElementById('particiation_amt_end_date').focus();
      //     return {
      //       message: "End date cannot be blank",
      //     };
      //   }),
    })
    .unknown(true);

  // Handling Async operation to save the data to the server
  const getParticipantData = async () => {
    const { data } = await transactionParticipantApi.request({
      trans_guid: transId,
      status: invStatus,
    });

    data.rows.map((item, index) => {
      item.index = index;
    });

    let amountInvested = _.sumBy(data.rows, (item) => Number(item.participation_amt));

    setTransData(data.transDetails);
    setParticipants(data.rows);
    setTotalAmtInvested(amountInvested);
    calcAvailableAmt(amountInvested);

    // ------------- Populating Investors list on the dropdown ------------- //
    if (investors.length === 0) {
      if (entityContext && typeof entityContext.entityDetails !== "undefined")
        fetchInvestorsList(entityContext.entityDetails.country_id, data.transDetails !== undefined ? data.transDetails.trans_owner : null);
      else fetchInvestorsList(country_id, data.transDetails !== undefined ? data.transDetails.trans_owner : null);
    }
  };

  // Calculate Amt Availability
  const calcAvailableAmt = (amountInvested = null) => {
    let availability = totalAdvanceAmt - (amountInvested === null ? totalAmtInvested : amountInvested);
    availability = availability > 0 ? availability : 0;

    if(selectedRow !== null && selectedRow.participation_amt !== undefined)
      availability = availability + parseFloat(selectedRow.participation_amt);

    setAvailableAmt(availability);
    return availability;
  }

  // Handling Async operation to save the data to the server
  const submitData = async (participantDetails) => {
    setisupdated(true);
    const { data } = await transactionApi.request({
      paData: participantDetails,
      trans_guid: transId,
    });

    if (data.success === false) toast.error(data.message);
    else {
      toast.success(data.message);
      setValues({ ...values, edit: false });

      if (showSummary) 
        getParticipantData();
        
      if(handleReload)
        handleReload();
             
      clearFields();
    }
  };

  //----------------- add participant details to context api ------------------//
  const addHandler = () => {
    // ------------------------- Custom validations -----------------------------//

    let amount_available =  totalAdvanceAmt - parseFloat(_.sumBy(participants, (item) => Number(item.participation_amt)));

    if (selectedParticipant.value === null) {
      participantRef.current.focus();
      toast.error("Please select the participant name from the list.");
      return;
    }

    if (participants.find((item) => item.participant_guid == selectedParticipant.value) && !values.edit) {
      toast.error(`${selectedParticipant.label} has already made an investment to this transaction.`);
      return;
    }
    // --------------------------------------------------------------------------//

    let participantDetails = [...participants];
    let updatedArr = [];

    if (values.edit) {
      updatedArr.push({
        index: values.index,
        participant_guid: selectedParticipant.value,
        participant_name: selectedParticipant.label,
        participation_amt_pctg: parseFloat(values.participation_amt_pctg),
        participation_amt: parseFloat(values.participation_amt),
        participation_amt_annual_rate: values.participation_amt_annual_rate,
        particiation_amt_paid_date: !values.particiation_amt_paid_date
          ? moment(new Date()).format("YYYY-MM-DD").toString()
          : values.particiation_amt_paid_date,
        particiation_amt_end_date: !values.particiation_amt_end_date
          ? null
          : values.particiation_amt_end_date,
        checked: false,
        edit: true,
        pa_type: values.type,
        pa_guid: values.pa_guid,
        fee: fee,
      });

      participantDetails[values.index] = updatedArr[0];
    } else {
      participantDetails.push({
        index: participantDetails.length,
        participant_guid: selectedParticipant.value,
        participant_name: selectedParticipant.label,
        participation_amt_pctg: values.participation_amt_pctg,
        participation_amt: parseFloat(values.participation_amt),
        participation_amt_annual_rate: values.participation_amt_annual_rate,
        particiation_amt_paid_date: !values.particiation_amt_paid_date
          ? moment(new Date()).format("YYYY-MM-DD").toString()
          : values.particiation_amt_paid_date,
        particiation_amt_end_date: !values.particiation_amt_end_date
          ? null
          : values.particiation_amt_end_date,
        pa_type: values.type,
        checked: false,
        fee: fee,
      });
    }

    if (context === true) addParticipant(participantDetails);
    else {
      setParticipants(participantDetails);
      submitData(participantDetails);
    }
    //calculateAvailableAmt(values.edit);
    calcAvailableAmt();
    clearFields();
    setFee(0);
  };

  //---------------- clear all fields ---------------------- //
  const clearFields = () => {
    setselectedParticipant({ label: "Select Participant", value: null });
    setValues({
      ...values,
      participation_amt_pctg: 0,
      participation_amt: 0,
      particiation_amt_paid_date: new Date(),
      particiation_amt_end_date: null,
      edit: false
    });

    // Setting edit property to false for each invoices
    if(showSummary){
      let participantDetails = participants.map((item) => {
        item.edit = false;
        return item;
      })
      setParticipants(participantDetails);
    }
  };

  // --- Calculating available participation amount w.r.t change in context --- //
  const calculateAvailableAmt = (edit = false) => {
    let amount_available = 0;
    let avilable_amount = 0;
    if (!edit) {
      amount_available = availableAmt - parseFloat(values.participation_amt);
      avilable_amount = amount_available >= 0 ? amount_available : 0;
    } else {
      amount_available = totalAdvanceAmt - parseFloat(_.sumBy(participants, (item) => Number(item.participation_amt)));
      avilable_amount = amount_available >= 0 ? amount_available : 0;
    }

    setAvailableAmt(avilable_amount);
  };
  //--------------------------------------------------------------------------- //

  const investorsListApi = useApi(coreService.getEntitiesList); // Custom hook for HTTP Requests

  // ------------ Getting the list of available investors -------------- //
  // const fetchInvestorsList = async (country, trans_owner = null) => {
  //   const { data } = await investorsListApi.request(country);
  //   setInvestors(data.filter(item => item.value !== trans_owner && item.value !== null));
  // };
  const fetchInvestorsList = async (country, trans_owner = null) => {
    const { data } = await investorsListApi.request(country);
    const { data: invPartners } = await investorsListApi.request(country, "Investment Partner");

    // Merging data and invPartners and filtering out duplicates and trans_owner
    const mergedData = [
        ...data.filter(item => item.value !== trans_owner && item.value !== null),
        ...invPartners.filter(item => item.value !== trans_owner && item.value !== null)
    ];

    setInvestors(mergedData);
  };
  // ------------------------------------------------------------------- //

  // ----------- Handle input change in Adv Percentage field ----------- //
  const handlePctgFieldChange = (event) => {
    const { value } = event.target;
    if (value !== null && value <= 100){
      let participation_amt = (value / 100) * availableAmt;
      setValues({ ...values, participation_amt, participation_amt_pctg: value });
    }
  };

  // --------------- Handle Participation amt field change ----------------------- //
  const handleAmtFieldChange = (event, maskedvalue, floatvalue) => {
    let participation_amt_pctg = Math.round((parseFloat(floatvalue) / availableAmt) * 100);
    setValues({
      ...values,
      participation_amt_pctg:
        isNaN(participation_amt_pctg) || availableAmt <= 0
          ? 0
          : participation_amt_pctg,
      participation_amt: floatvalue,
    });
  };

  //------------------Custom hook to handle form data-----------------------------
  const { values, setValues, handleChange, handleSubmit } = useForm(
    addHandler,
    schema
  ); // Custom Hook to handle Form Validation

  useEffect(() => {
    
    // Set default value for Annual Rate
    if (!values["participation_amt_annual_rate"])
      setValues({
        ...values,
        participation_amt_annual_rate: annualRate,
        particiation_amt_paid_date: new Date(),
        particiation_amt_end_date: null,
      });

    if (context === false) {
      getParticipantData();
    } else {
      const totalParticipationFunds = _.sumBy(participants, "participation_amt");
      setAvailableAmt(totalAdvanceAmt - parseFloat(totalParticipationFunds));
    }

    if(selectedRow && selectedRow.pa_guid) 
      editHandler(selectedRow);
    else
      setValues({
        ...values,
        type: { label: "Percentage", value: "percentage" },
        particiation_amt_paid_date: new Date(),
        particiation_amt_end_date: null,
      });
      
  }, []);

  useEffect(() => {
    if(values.participation_amt){

      if(values.type.value === "fixed")
        setFee(values.participation_amt_annual_rate ? values.participation_amt_annual_rate : 0);
      else
        handleFeeCalculation(
          values.particiation_amt_end_date ? values.particiation_amt_end_date : null,//moment(new Date()).format("YYYY-MM-DD").toString(), 
          values.type.value,
          values.participation_amt_annual_rate ? values.participation_amt_annual_rate : null,
          values.particiation_amt_paid_date ? values.particiation_amt_paid_date : null
        );
    }
  },[values])

  const editHandler = (row) => {
    setFee(row.fee);

    setValues({
      ...values,
      ...row,
      edit: true,
      participation_amt_annual_rate: row.participation_amt_annual_rate
        ? row.participation_amt_annual_rate
        : row.participation_annual_amt,
      type: row.participation_amt_annual_rate
        ? { label: "Percentage", value: "percentage" }
        : { label: "Fixed", value: "fixed" },
      pa_guid: row.pa_guid,
      participation_amt_pctg: ( parseFloat(row.participation_amt) / totalAdvanceAmt ) * 100
    });

    setselectedParticipant({
      label: row.participant_name,
      value: row.participant_guid,
    });

    let available = selectedRow === null ? calcAvailableAmt() : availableAmt;
    setAvailableAmt(available + parseFloat(row.participation_amt));

    // Hide the Edit button once the row is selected for Editing
    if(showSummary){
      let participantDetails = participants.map((item, index) => {
        item.edit = row.index === index ? true : false;
        return item;
      })
      setParticipants(participantDetails);
    }
  };

  const handleFeeCalculation = (end_date, type, percentage = null, start_date = null) => {
    if(end_date){
      if (type == "percentage") {
        let days = moment(end_date).diff(moment(start_date ? start_date : values.particiation_amt_paid_date).format("YYYY-MM-DD").toString(), "days");
        let total_interest =  ((percentage ? percentage : values.participation_amt_annual_rate) /365) * days;
        let fee_amount =  total_interest *  (values.participation_amt ? values.participation_amt/100 : 0);
        fee_amount > 0 ? setFee(fee_amount.toFixed(2)) : setFee(0);
      } else {
        setFee(
          values.participation_amt_annual_rate
            ? values.participation_amt_annual_rate
            : 0
        );
      }
    }else
      setFee(0);
  };

  return (
    <>
      <Modal
        show={openParticiapnt}
        onHide={(e) => {
          onClose(isUpdated);
          setParticipant(false);
        }}
        size="lg"
      >
        {(transactionParticipantApi.loading === true ||
          transactionApi.loading === true) && <LoadingSpinner />}
        <Modal.Header closeButton>
          <h4 className="modal-title">Add Participation</h4>
        </Modal.Header>

        <Modal.Body>
          {/*------------------------ Info Row ---------------------------*/}

          <CommonHeader transData={transData} totalInvAmt={totalInvAmt} />

          <form onSubmit={handleSubmit} className="form-horizontal">
            <div className="row">
              {/*-------------------- First Column --------------------*/}
              <div className="col-lg-4">
                <Picker
                  items={investors}
                  selectedItem={selectedParticipant}
                  onSelectItem={(option) => setselectedParticipant(option)}
                  reference={participantRef}
                />
              </div>

              {/*-------------------- Second Column --------------------*/}
              <div className="col-lg-8 border-left-dashed">
                <FormGroup
                  label="Available Amt"
                  type="horizontal"
                  input={<CurrencyLabel value={availableAmt}></CurrencyLabel>}
                />

                <TextInput
                  name="participation_amt_pctg"
                  label="Participation Amt %"
                  placeholder="Amt Percentage"
                  value={values.participation_amt_pctg}
                  type="horizontal"
                  onChange={handlePctgFieldChange}
                />

                <FormGroup
                  label="Participation Amt $"
                  type="horizontal"
                  input={
                    <CurrencyText
                      value={values.participation_amt}
                      onChangeEvent={handleAmtFieldChange}
                      placeholder="Invested Amount"
                      maxValue={availableAmt}
                      reference={participationAmtRef}
                    />
                  }
                />
                <FormGroup
                  label="Type"
                  type="horizontal"
                  input={
                    <Picker
                      items={[
                        { label: "Percentage", value: "percentage" },
                        { label: "Fixed", value: "fixed" },
                      ]}
                      selectedItem={values.type}
                      onSelectItem={(opt) => {
                        setValues({
                          ...values,
                          ["type"]: { label: opt.label, value: opt.value },
                        });
                      }}
                    />
                  }
                />

                <TextInput
                  name="participation_amt_annual_rate"
                  label={(values.type !== undefined && values.type.value === "fixed") ? "Fixed Fee Charged $" : "Annual Value %"} 
                  placeholder={(values.type !== undefined && values.type.value === "fixed") ? "Fixed Fee Charged $" : "Annual Value %"} 
                  value={values.participation_amt_annual_rate}
                  onChange={(e) => {
                    if(!isNaN(e.target.value))
                      handleChange(e);
                  }}
                  type="horizontal"
                  reference={AnnualRateRef}
                />

                <FormGroup
                  label="Start Date"
                  type="horizontal"
                  input={
                    <DateTimePicker
                      name="particiation_amt_paid_date"
                      selectedDate={values.particiation_amt_paid_date}
                      reference={PaidDateRef}
                      enableWeekDays={true}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  }
                />
                <FormGroup
                  label="Return Date"
                  type="horizontal"
                  input={
                    <DateTimePicker
                      name="particiation_amt_end_date"
                      selectedDate={values.particiation_amt_end_date}
                      reference={EndDateRef}
                      enableWeekDays={true}
                      onChange={(value) => {
                        handleChange(value);
                      }}
                    />
                  }
                />
                <FormGroup
                  label="Fee Amt"
                  type="horizontal"
                  input={<h4><CurrencyLabel value={fee}></CurrencyLabel></h4>}
                />
              </div>
            </div>

            <div className="row m-t-sm">
              <div className="col-lg-12 ">
                {!values.edit ? (
                  <GeneralButton
                    className="btn btn-primary pull-right m-l-xs"
                    name="Add Participation"
                    disabled={transactionApi.loading}
                  ></GeneralButton>
                ) : (
                  <GeneralButton
                    className="btn btn-primary pull-right m-l-xs"
                    name="Update Participation"
                    disabled={transactionApi.loading}
                  ></GeneralButton>
                )}
              </div>
            </div>
          </form>

          {participants.length > 0 && showSummary && (
            <ParticipantSummary
              participants={participants}
              transData={transData}
              trans_guid={transId}
              editHandler={editHandler}
              edit={true}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
